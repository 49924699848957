html,
body,
#root {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: transparent;
}

img {  
  position: relative;
  font-size: 0;
}

.default-bg-gradient {
  background: linear-gradient(180deg, #004A78 0%, #006BAD 49.48%, #004A78 100%);
}